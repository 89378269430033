import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// Services
import { FileService } from 'src/app/services/file/file.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionService } from 'src/app/services/session/session.service';
// Utils
import * as downloadjs from 'downloadjs';

@Component({
  selector: 'vc-session-settings',
  templateUrl: './session-settings.component.html',
  styleUrls: ['./session-settings.component.scss'],
})
export class SessionSettingsComponent implements OnChanges {
    @Input() session: any;
    @ViewChild('fileInput', {static: false}) fileInput: any;
    uploading:boolean = false;
    displayTimePicker: boolean = false;

    constructor(
        private toastr: ToastrService,
        private messageService: MessageService,
        private sessionService: SessionService,
        private fileService: FileService,
    ) {}

    ngOnChanges() {
        setTimeout(()=> {
            if (this.session) {
                if (!(this.session.day instanceof Date)) {                
                    this.session.day = new Date(this.session.day);
                }
                if (!(this.session.start_time instanceof Date)) {         
                    this.session.start_time = new Date(this.session.start_time);
                }
                if (!(this.session.end_time instanceof Date)) {            
                    this.session.end_time = new Date(this.session.end_time);
                }
                this.displayTimePicker = true;
            }
        }, 0);
    }
    
    updateSession(session:any) {
        console.log('[updateSession]', session);
        if (!session) {
            this.toastr.warning('Missing session details.');
            return;
        }
        let copy = Object.assign({}, session);
        delete copy.uploads;
        this.sessionService.patch(session._id, copy).subscribe(
            data => this.toastr.success(`Updated the session "${session.name}".`),
            data => {
                this.toastr.error(`Could not update the session information.`)
                console.log('[updateSession@session-settings.comp]', data)
            }
        )
    }
    
    toggleChat() {
        this.sessionService.patch(this.session._id, {chat_enabled: this.session.chat_enabled ? false: true, event_id: this.session.event_id});
    }

    clearChat() {
        console.log('[clearChat]');
        this.messageService.remove(null, {
            query: {
                session_id: this.session._id,
                event_id: this.session.event_id,
            }
        }).subscribe(
        data => this.toastr.success('Deleted chat history.'),
        error => {
            this.toastr.error('Could not delete the chat history.')
            console.error(error)
        }
        );
    }

    uploadFile(file:File) {
        const filesize = Number(((file.size/1024)/1024).toFixed(4)); // MB
        if (filesize > 20) {
            this.toastr.warning('Filesize is too big (max 20MB).');
            return;
        }
        this.uploading = true;
        const _this = this;
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function() {
            const base64 = `data:${file.type};base64,${btoa(String(reader.result))}`;            
            _this.fileService.create({
                id: file.name,
                uri: base64,
                event_id: _this.session.event_id,
                session_id: _this.session._id,
            }).subscribe(
                data => {
                    _this.uploading = false;
                    _this.toastr.success('Upload was successful.');
                    _this.fileInput.nativeElement.value = null;
                    data.upload_id = data.id;
                    !_this.session.uploads || !Array.isArray(_this.session.uploads) ? _this.session.uploads = [data] : _this.session.uploads.push(data);
                },
                error => {
                    _this.uploading = false;
                    console.error('[Error @SessionSettingsComponent] uploadFile()', error)
                    _this.toastr.error(`Could not upload file.`, 'Error!');
                }
            )
        };
        reader.onerror = function() {
            console.error('[Error @SessionSettingsComponent] uploadFile() - reader. Code 136');
        };
    }

    downloadFile(upload:any) {
        this.fileService.download(upload.upload_id);
    }

    deleteFile(upload:any) {
        this.fileService.remove(upload.upload_id).subscribe(
            data => {
                this.toastr.success(`Delete file "${upload.upload_id}".`);
                const index = this.session.uploads.findIndex(u => u.upload_id == upload.upload_id);
                if (index > -1) {
                    this.session.uploads.splice(index, 1);
                }
            },
            error => {
                console.error('[Error @SessionSettingsComponent] deleteFile:', error);
                this.toastr.error('Could not delte the file.');
            }
        )
    }

}