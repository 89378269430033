import { Component } from '@angular/core';

@Component({
  selector: 'vc-industrieaussteller',
  templateUrl: './industrieaussteller.component.html',
  styleUrls: ['./industrieaussteller.component.scss'],
})
export class IndustrieausstellerComponent {

    constructor() {}

}
