import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { FileService } from 'src/app/services/file/file.service';

@Component({
    selector: 'vc-session-modal',
    templateUrl: './session-modal.component.html',
    styleUrls: ['./session-modal.component.scss']
})

export class SessionModalComponent {
  session: any;
  list: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public fileService: FileService,
  ) {}

  downloadFile(upload:any) {
    this.fileService.download(upload.upload_id);
  }

}
