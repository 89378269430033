import { Component, Input, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
// Services
import { ChannelService } from 'src/app/services/channel/channel.service';
import { FileService } from 'src/app/services/file/file.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionService } from 'src/app/services/session/session.service';
// Components
import { SessionCreateModalComponent } from '../session-create-modal/session-create-modal.component';
import { SessionEditModalComponent } from '../session-edit-modal/session-edit-modal.component';

@Component({
  selector: 'vc-channel-settings',
  templateUrl: './channel-settings.component.html',
  styleUrls: ['./channel-settings.component.scss'],
})
export class ChannelSettingsComponent implements OnChanges {
  @Input() channel: any;
  @Input() sessions: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private channelService: ChannelService,
    private modalService: BsModalService,
    private sessionService: SessionService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnChanges() {
  }
  
  updateChannel(channel:any) {
      console.log('[updateChannel]', channel);
      if (!channel) {
          this.toastr.warning('Missing channel details.');
          return;
      }
      this.channelService.patch(channel._id, channel).subscribe(
          data => this.toastr.success(`Updated the channel "${channel.name}".`),
          data => {
              this.toastr.error(`Could not update the channel information.`)
              console.log('[updateChannel@channel-settings.comp]', data)
          }
      )
  }

  selectActiveSession(sessionId:any) {
    this.channelService.patch(this.channel._id, {
      active_session_id: sessionId
    }).subscribe(
      data => {},
      data => console.error('[selectActiveSession@channel.comp]', data)
    )
  }

  addNewSession() {
    const modalRef: BsModalRef = this.modalService.show(SessionCreateModalComponent);
    modalRef.content.channel = this.channel;
  }

  deleteChannel() {
    if (window.confirm(`Do you want to delte the channel "${this.channel.name}".`)) { 
      this.channelService.remove(this.channel._id).subscribe(
        success => {
          this.toastr.success(`Deleted channel "${this.channel.name}".`);
          this.router.navigate(['event', this.channel.event_id]);
        },
        error => {
          console.error('[deleteChannel@channel.comp]', error);
          this.toastr.error('Could not delete channel.');
        }
      )
    }
  }

  deleteSession(sessionId:string) {
    const session = this.sessions.find(e => e._id === sessionId);
    if (window.confirm(`Do you want to delte the session "${session.name}".`)) { 
      // this.sessionSelect.value = this.activeSession._id;
      this.sessionService.remove(session._id).subscribe(
        success => {
          this.toastr.success(`Deleted session "${session.name}".`);
        },
        error => {
          console.error('[deleteSession@channel.comp', error);
          this.toastr.error('Could not delete session.');
        }
      )
    }
  }

  editSession(sessionId:any) {
    const session = this.sessions.find(s => s._id === sessionId);
    if (!session) {
      this.toastr.warning('Could not find a matching session.');
      return;
    }
    const modalRef: BsModalRef = this.modalService.show(SessionEditModalComponent);
    modalRef.content.session = session;
  }

}  
