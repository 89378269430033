import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MessageService extends BaseService<any> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'messages');
  }

  watch(filter ? : any): Observable < any > {
    const defaultFilter = {
      query: {
        $sort: {
          createdAt: -1
        },
        $limit: 25
      }
    };
    return super.watch(filter ? filter : defaultFilter).pipe(
      map((messages:any) => messages.map((m:any) => {
        m.content = decodeURIComponent(m.content);
        return m;
      }))
    );
  }

  send(message: any) {
    message.content = encodeURIComponent(message.content);
    return super.create(message);
  }

}
