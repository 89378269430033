import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event/event.service';
import { FileService } from 'src/app/services/file/file.service';
import { SessionService } from 'src/app/services/session/session.service';
// Utils
import { takeUntil, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit, OnDestroy {
  $destroyed: Subject<boolean> = new Subject<boolean>();
  session:any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private eventService: EventService,
    private sessionService: SessionService,
    private fileService: FileService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      (data: any) => {
        if (Object.keys(data).includes('sessionId') && data['sessionId']) { // && Number.isInteger(Number(data['channelId']))
          this.subscribeToSession(data['sessionId']).subscribe(
            session => {
              console.log('session', session)
              this.session = session;
            },
            error => {
              this.toastr.error('Could not load session!');
              this.router.navigate(['dashboard']);
            }
          );
        } else {
          this.toastr.error('No matching session found!');
          this.router.navigate(['dashboard']);
        }
        // load event if it not set (after page reload)
        if (!this.eventService.activeEvent) {
          if (Object.keys(data).includes('eventId') && data['eventId']) { // && Number.isInteger(Number(data['channelId']))
            this.eventService.get(data['eventId']).subscribe(
              event => {
                console.log('event', event)
                this.eventService.$activeEvent.next(event);
              },
              error => this.toastr.error('Could not load channel!')
            );
          }
        }
      },
      error => {
        this.toastr.error('Cloud not load channel!');
        this.router.navigate(['dashboard']);
      }
    );
  }

  ngOnDestroy() {
    console.warn('Destroyed SESSION.COMPONENT');
    this.$destroyed.next();
    this.$destroyed.complete();
  }
  
  getEventRole = (event_id:string) => this.authService.getEventRole(event_id);

  subscribeToSession(sessionlId: string):Observable<any> {
    return this.sessionService.watchOne(sessionlId).pipe(
      takeUntil(this.$destroyed.asObservable()),
      tap(e => console.log('SESSION', e)),
    );    
  }

  downloadFile(upload:any) {
    this.fileService.download(upload.upload_id);
  }

}
