import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as Clappr from 'clappr'
import { fromEvent, timer } from 'rxjs';
import { debounce } from 'rxjs/internal/operators/debounce';

@Component({
  selector: 'vc-stream-player',
  templateUrl: './stream-player.component.html',
  styleUrls: ['./stream-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreamPlayerComponent implements OnChanges {
    @Input() rawUrl: string;
    url:SafeResourceUrl;
    playerType: string;

    constructor(
        private sanitizer: DomSanitizer,
    ) {}

    ngOnChanges() {
        if (this.rawUrl.includes('stream.mux')) {
            this.playerType = 'mux';
            setTimeout(() => {
                const e: any = document.querySelector('#muxPlayer');
                e.innerHTML = '';
                new Clappr.Player({
                    source: this.rawUrl,
                    parentId: "#muxPlayer",
                    width: e.offsetWidth,
                    height: e.offsetWidth/1.77,
                    autoPlay: true
                }), 100;
            });          
            fromEvent(window, 'resize').pipe(debounce(() => timer(100))).subscribe(data => {
                const e: any = document.querySelector('#muxPlayer')
                if (e) {
                    const playerDiv:any = document.querySelector('#muxPlayer div');
                    playerDiv.style.width = e.offsetWidth+'px';
                    playerDiv.style.height = e.offsetWidth/1.77+'px';
                }
            }) 
        } else {
            this.playerType = 'iframe';
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.rawUrl);
        }
    }
}
