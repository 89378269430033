import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import * as download from 'downloadjs';
import { take } from 'rxjs/operators';
// import { AuthService } from '../auth.service';

@Injectable()
export class FileService extends BaseService<any> {

  constructor(
    protected feathers: Feathers,
    // protected http: HttpClient,
    // protected auth: AuthService,
  ) {
    super(feathers, 'uploads');
  }

  // use REST for upload
  // create(data:any) {
  //   const options: any = { 
  //     headers :  new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': this.auth.authData.accessToken
  //     })
  //   }
  //   return this.http.post('http://localhost:3030/uploads', data, options);
  // }

  download(id:string) {      
    super.get(id).pipe(take(1)).subscribe(data => download(data.uri, data.id));
  }

  getSrc(id:string) {
    return super.get(id).pipe(take(1));
  }

}
