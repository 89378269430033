import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// components
import { ChatComponent } from './components/chat/chat.component';
import { ChannelComponent } from './components/channel/channel.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventComponent } from './components/event/event.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SessionComponent } from './components/session/session.component';
import { IndustrieausstellerComponent } from './components/industrieaussteller/industrieaussteller.component';

/*
  Our app's routes.
  If you don't know what this means, check https://angular.io/docs/ts/latest/guide/router.html
 */
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'chat',
        component: ChatComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'channel/:channelId',
        component: ChannelComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'event/:eventId',
        component: EventComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'event/:eventId/channel/:channelId',
        component: ChannelComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'event/:eventId/session/:sessionId',
        component: SessionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'session/:sessionId',
        component: SessionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'industrieaussteller',
        component: IndustrieausstellerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'signup',
        component: SignupComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
