import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { ChannelService } from 'src/app/services/channel/channel.service';
import { EventService } from 'src/app/services/event/event.service';
import { FileService } from 'src/app/services/file/file.service';
import { SessionService } from 'src/app/services/session/session.service';
// Component
import { SessionCreateModalComponent } from '../session-create-modal/session-create-modal.component';
import { SessionEditModalComponent } from '../session-edit-modal/session-edit-modal.component';
import { SessionModalComponent } from '../session-modal/session-modal.component';
// Utils
import { takeUntil, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import * as downloadjs from 'downloadjs';

@Component({
  selector: 'vc-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit, OnDestroy {
    channel: any;
    sessions: any[] = [];
    sessionIndex:number = 0;
    activeSession: any;
    $destroyed: Subject<boolean> = new Subject<boolean>();
    @ViewChild('sessionSelect', {static: false}) sessionSelect: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private channelService: ChannelService,
    private eventService: EventService,
    private sessionService: SessionService,
    private modalService: BsModalService,
    private fileService: FileService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      (data: any) => {
        if (Object.keys(data).includes('channelId') && data['channelId']) { // && Number.isInteger(Number(data['channelId']))
            this.subscribeToChannel(data['channelId']).subscribe(
              channel => {       
                console.log('channel', channel)         
                this.channel = channel;
                this.subscribeToChannelSessions();
              },
              error => {
                this.toastr.error('Could not load channel!');
                this.router.navigate(['dashboard']);
              }
            );
        } else {
            this.toastr.error('No matching channel found!');
            this.router.navigate(['dashboard']);
        }
        // load event if it not set (after page reload)
        if (!this.eventService.activeEvent) {
          if (Object.keys(data).includes('eventId') && data['eventId']) { // && Number.isInteger(Number(data['channelId']))
            this.eventService.get(data['eventId']).subscribe(
              event => {       
                console.log('event', event)         
                this.eventService.$activeEvent.next(event);
              },
              error => this.toastr.error('Could not load channel!')
            );
        }
        }
      },
      error => {
        this.toastr.error('Cloud not load channel!');
        this.router.navigate(['dashboard']);
      }
    );
  }  

  ngOnDestroy() {
    console.warn('Destroyed CHANNEL.COMPONENT');
    this.$destroyed.next();
    this.$destroyed.complete();
  }
  
  getEventRole = (event_id:string) => this.authService.getEventRole(event_id);
  
  subscribeToChannel(channelId: string):Observable<any> {
    return this.channelService.watchOne(channelId).pipe(
      takeUntil(this.$destroyed.asObservable()),
      tap(e => console.log('CHANNEL', e)),
      tap(channel => this.setActiveSession(channel, this.sessions)),
    );    
  }

  subscribeToChannelSessions() {
    this.sessionService.watch({
        query: { 
            channel_id: this.channel._id,
        }
    }).pipe(
      takeUntil(this.$destroyed.asObservable()),
      tap(e => console.log('SESSIONS', e)),
      tap(sessions => this.setActiveSession(this.channel, sessions)),
    ).subscribe(
      data => this.sessions = data,
      error => this.toastr.error('Could not load sessions!')
    );    
  }

  setActiveSession(channel: any, sessions:any) {
      console.log('setActiveSession', channel, sessions)
      if (sessions && channel) {
        if (channel.active_session_id === null) {
            this.activeSession = undefined;
            return;
        }
        const candidate = sessions.find(s => s._id === channel.active_session_id);
        console.log('activeSession CANDIDATE', candidate)
        if (!this.activeSession || this.activeSession._id !== candidate._id || this.activeSession.stream_url !== candidate.stream_url) {            
            this.activeSession = candidate;
            console.log('%cSET ACTIVE SESSION', 'color:green;', this.activeSession)
        } else if (this.activeSession.stream_url === candidate.stream_url) {
          //  only update other properties to prevent stream reloading
          const properties = ['chat_enabled', 'description', 'start_time', 'end_time', 'name', 'status'];
          for (let p of properties) {
            this.activeSession[p] = candidate[p];
          }
          console.log('%cUPDATED ACTIVE SESSION', 'color:green;', this.activeSession)
        } else {
          this.activeSession = undefined;
          console.log('%cRESETSET ACTIVE SESSION', 'color:red;' ,this.activeSession)
        } 
      }     
  }

  nextSession() {
    return this.sessionIndex+1 < this.sessions.length ? this.sessionIndex++ : this.sessionIndex = 0;
  }

  previousSession() {
      return this.sessionIndex-1 >= 0 ? this.sessionIndex-- : this.sessionIndex = this.sessions.length-1;
  }

  openSessionModal(session:any) {
    const modalRef: BsModalRef = this.modalService.show(SessionModalComponent);
    modalRef.content.session = session;
  }

  selectActiveSession(sessionId:any) {
    this.channelService.patch(this.channel._id, {
      active_session_id: sessionId
    }).subscribe(
      data => {},
      data => console.error('[selectActiveSession@channel.comp]', data)
    )
  }

  addNewSession() {
    const modalRef: BsModalRef = this.modalService.show(SessionCreateModalComponent);
    modalRef.content.channel = this.channel;
  }

  deleteChannel() {
    if (window.confirm(`Do you want to delte the channel "${this.channel.name}".`)) { 
      this.channelService.remove(this.channel._id).subscribe(
        success => {
          this.toastr.success(`Deleted channel "${this.channel.name}".`);
          this.router.navigate(['event', this.channel.event_id]);
        },
        error => {
          console.error('[deleteChannel@channel.comp]', error);
          this.toastr.error('Could not delete channel.');
        }
      )
    }
  }

  deleteSession(sessionId:string) {
    const session = this.sessions.find(e => e._id === sessionId);
    if (window.confirm(`Do you want to delte the session "${session.name}".`)) { 
      // this.sessionSelect.value = this.activeSession._id;
      this.sessionService.remove(session._id).subscribe(
        success => {
          this.toastr.success(`Deleted session "${session.name}".`);
        },
        error => {
          console.error('[deleteSession@channel.comp', error);
          this.toastr.error('Could not delete session.');
        }
      )
    }
  }

  editSession(sessionId:any) {
    const session = this.sessions.find(s => s._id === sessionId);
    if (!session) {
      this.toastr.warning('Could not find a matching session.');
      return;
    }
    const modalRef: BsModalRef = this.modalService.show(SessionEditModalComponent);
    modalRef.content.session = session;
  }

  downloadFile(upload:any) {
    this.fileService.download(upload.upload_id);
  }

  isUrl(url:string) {
    if (url.match(/(http)|(https):\/\//g) !== null) {            
      return true;
    }
    return false;
  }

}
