import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Abstraction layer for auth. Nice to have when things get more complicated.
 */
@Injectable()
export class AuthService {
  authData:any;

  constructor(private feathers: Feathers, private router: Router) {}

  public logIn(credentials?): Promise<any> {
    return this.feathers.authenticate(credentials).then(data => {
      console.log('[authService] login', data);
      this.authData = data;
      return data;
    });
  }

  public logOut() {
    this.feathers.logout().then(
      data => {
        console.log('Lougout', data)
        delete this.authData;
        window.location.reload();
      }
    )
  }

  public getUser() {
    if (this.authData && this.authData.user) {
      return this.authData.user;
    } else {
      console.error('[auth.service] No userdata found.');
      this.logOut();
    }
  }

  public getEventRole(event_id:string) {
    if (!this.authData.user.eventRoles) {
      console.error('[auth.service] User has no eventRoles.');
    }
    let role = this.authData.user.eventRoles.find((eR:any) => eR.event_id === event_id);
    if (role !== undefined) {
      role = role.role;
    }
    return role;
  }

}
