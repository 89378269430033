import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Feathers } from './services/feathers.service';
import { ToastrModule } from 'ngx-toastr';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

// ngx-bootstrap
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
const NGXBS = [
  ButtonsModule.forRoot(),
  BsDatepickerModule.forRoot(),
  CollapseModule.forRoot(),
  TooltipModule.forRoot(),
  TimepickerModule.forRoot(),
  ModalModule.forRoot(),
];

// servies
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { BaseService } from './services/base.service';
import { ChannelService } from './services/channel/channel.service';
import { DataService } from './services/data.service';
import { EventService } from './services/event/event.service';
import { FileService } from './services/file/file.service';
import { MessageService } from './services/message/message.service';
import { SessionService } from './services/session/session.service';

// components
import { AppComponent } from './app.component';
import { AsyncImgComponent } from './components/async-img/async-img.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChannelComponent } from './components/channel/channel.component';
import { ChannelCreateModalComponent } from './components/channel-create-modal/channel-create-modal.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventComponent } from './components/event/event.component';
import { LoginComponent } from './components/login/login.component';
import { SessionCreateModalComponent } from './components/session-create-modal/session-create-modal.component';
import { SessionEditModalComponent } from './components/session-edit-modal/session-edit-modal.component';
import { SessionModalComponent } from './components/session-modal/session-modal.component';
import { SessionSettingsComponent } from './components/session-settings/session-settings.component';
import { SignupComponent } from './components/signup/signup.component';
import { StreamPlayerComponent } from './components/stream-player/stream-player.component';
import { SessionComponent } from './components/session/session.component';
import { ChannelSettingsComponent } from './components/channel-settings/channel-settings.component';
import { ChannelEditModalComponent } from './components/channel-edit-modal/channel-edit-modal.component';
import { IndustrieausstellerComponent } from './components/industrieaussteller/industrieaussteller.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    ChannelComponent,
    ChannelCreateModalComponent,
    DashboardComponent,
    EventComponent,
    LoginComponent,
    SessionModalComponent,
    SessionCreateModalComponent,
    SessionEditModalComponent,
    SessionSettingsComponent,
    SignupComponent,
    StreamPlayerComponent,
    SessionComponent,
    AsyncImgComponent,
    ChannelSettingsComponent,
    ChannelEditModalComponent,
    IndustrieausstellerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressAnimation: 'decreasing',
      progressBar: true, 
      enableHtml: true, 
      easing: 'ease-in',      
    }),
    ...NGXBS,
    FeatherModule.pick(allIcons),
    HttpClientModule,
  ],
  providers: [
    Feathers,
    AuthGuard,
    AuthService,
    BaseService,
    ChannelService,
    DataService,
    EventService,
    FileService,
    MessageService,
    SessionService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AsyncImgComponent,
    ChatComponent,
    ChannelCreateModalComponent,
    ChannelEditModalComponent,
    ChannelSettingsComponent,
    SessionModalComponent,
    SessionCreateModalComponent,
    SessionEditModalComponent,
    SessionSettingsComponent,
    StreamPlayerComponent,
  ]
})
export class AppModule { }
